:root {
  --pink: #ef798a;
  --red: #ec483f;
  --yellow: #fabc2a;
  --green: #44b97d;
  --blue: #5a8eff;
  --blue10: #eff4ff;
  --navy: #296f93;
  --purple: #b9c4fe;
  --grey: #c4c4c4;
  --light-grey: #f2f2f2;
  --black: #2b303a;
  --white: #ffffff;
}

body,
button {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

b {
  color: var(--blue);
  font-weight: 700;
}

.black-bold {
  color: var(--black);
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  height: 48px;
  width: 100%;
  /* max-width: 350px; */
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 32px 24px 32px 24px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  align-items: center;
  background-color: white;
}

.back-arrow {
  width: 22px;
  padding-left: 3px;
}

.avatar {
  width: 48px;
  height: 48px;
}

.p-header {
  width: 100%;
  margin-top: 10px;
}

.tutorial-panel {
  width: 100%;
  height: 100%;
  background-color: var(--blue10);
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 32px;
  align-items: center;
  flex-grow: 4;
  /* max-width: 350px; */
}

.tutorial-title-3 {
  width: 100%;
  font-weight: 500;
  margin-bottom: 32px;
  height: 72px;
}
.tutorial-title-2 {
  width: 100%;
  font-weight: 500;
  margin-bottom: 24px;
}

.button-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.logbook {
  margin-top: 32px;
  background: var(--white);
  border: 1px solid var(--black);
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding: 16px 24px;
  font-weight: 400;
}
.logbook:hover {
  background: var(--light-grey);
}

.logbook-notif {
  text-align: center;
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 42px;
  right: -12px;
  background: var(--red);
  border-radius: 60px;
  z-index: 100;
  color: var(--white);
  font-weight: 700;
  font-size: 12px;
  vertical-align: middle;
  line-height: 24px;
}

.console-wrapper {
  position: relative;
  width: 75%;
  padding-bottom: 24px;
  margin-bottom: auto;
}

.console-image {
  width: 100%;
}

.console-select {
  position: absolute;
  background-color: #2b303a;
  opacity: 0%;
}
.step-image {
  width: 100%;
  margin-bottom: 24px;
  border-radius: 8px;
  margin-top: auto;
}

.console-gif {
  position: absolute;
  width: 100vw;
}

.step8-select {
  position: absolute;
  background-color: #2b303a;
  opacity: 00%;
}

.step8-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.step8-image {
  width: 100%;
}
.step10-select {
  position: absolute;
  background-color: #2b303a;
}

.step10-message {
  margin-top: auto;
  margin-bottom: 16px;
}

.step10-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.step10-image {
  width: 100%;
}

.select-message {
  text-align: center;
  margin-bottom: 16px;
}

.step-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  background: var(--white);
  border: 1px solid #2b303a;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 100;
  margin-top: auto;
  margin-bottom: 16px;
}
.button-expand-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  overflow-y: none;
  padding: 16px 24px;
  background: var(--white);
  border: 1px solid #2b303a;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 100;
  gap: 24px;
  margin-bottom: 16px;
}

.button-expand-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.step-button-arrow {
  width: 24px;
}

.step-button-wrapper:hover {
  background: var(--light-grey);
}

.step-nav-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 32px; */
  margin-top: auto;
  z-index: 200;
}

.step-nav-number {
  font-weight: 500;
}

.video-hint-overlay-bg {
  background-color: rgba(43, 48, 58, 0.45);
  width: 100vw;
  height: 100vh;
  z-index: 995;
  position: absolute;
  left: 0;
  top: 0;
  padding: 32px 16px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-hint-overlay {
  background-color: var(--white);
  z-index: 999;
  padding: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  border-radius: 8px;
}

.video-hint-overlay-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.video-hint {
  width: 100%;
  border-radius: 8px;
}

ol {
  margin: 0;
}

.how-it-works-title {
  max-width: 80%;
}

.how-it-works {
  width: 100%;
  gap: 16px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  margin-top: auto;
  overflow-y: scroll;
  border-radius: 8px;
  padding: 16px 21px 32px 32px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  max-height: 50vh;
}

.how-it-works-gif {
  width: 100%;
}

.youtube-tutorial {
  width: 300px;
  height: 530px;
}

.scratch-image-wrapper {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scratch-cover {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.scratch-image {
  width: 100%;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}
.fade-out-2 {
  opacity: 0;
  transition: opacity 2s ease;
}
.home-avatar {
  margin-top: 56px;
  margin-bottom: 24px;
}

.home-title {
  width: 100%;
  text-align: left;
  font-weight: 500;
  padding-bottom: 16px;
}

.home-badge-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 16px;
}
.home-badge {
  width: 45%;
}
.home-name {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 48px;
}

.profile-wrapper {
  background-color: rgba(43, 48, 58, 0.45);
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  z-index: 900;
  position: absolute;
  left: 0;
  top: 0;
  padding: 100px 0px 0px 0px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-container {
  background-color: var(--white);
  z-index: 999;
  padding: 20px;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.profile-title {
  font-weight: 500;
  margin-bottom: 16px;
  width: 100%;
}

.profile-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: 85vh;
  padding-bottom: 16px;
}

.profile-avatar-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding-bottom: 24px;
}

.profile-avatar {
  border-radius: 50%;
  width: 80px;
}

.profile-name {
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
  height: 56px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid var(--grey);
  border-radius: 8px;
}

.profile-name:focus {
  border: 2px solid var(--blue);
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

.profile-save {
  margin-top: 32px;
  background: var(--white);
  border: 1px solid var(--black);
  box-sizing: border-box;
  border-radius: 8px;
  width: 200px;
  padding: 16px 24px;
  margin-bottom: 8px;
  font-weight: 400;
}
.profile-save:hover {
  background: var(--light-grey);
}

.logbook-wrapper {
  background-color: rgba(43, 48, 58, 0.45);
  width: 100vw;
  height: 100vh;
  z-index: 900;
  position: absolute;
  left: 0;
  top: 0;
  padding: 100px 0px 0px 0px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.logbook-container {
  background-color: var(--white);
  z-index: 999;
  padding: 20px;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.logbook-entries {
  overflow-y: scroll;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logbook-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.logbook-title {
  margin-bottom: 16px;
  width: 100%;
}

.logbook-entry {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: baseline;
}

.logbook-ul {
  margin: 0px 0px 24px 0px;
}

.intro-animation-container {
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  z-index: 999;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-video {
  width: 140%;
}

.exploded-image {
  width: 100%;
  margin-bottom: 24px;
}

.download-image {
  width: 70%;
  margin: 0px 0px 56px 0px;
}

.desktop-cont{
  width:100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b303a;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  body,
  button {
    font-size: 24px;
  }
  .home-avatar {
    width: 144px;
    margin-top: 96px;
    margin-bottom: 32px;
  }
  .home-title {
    padding-bottom: 24px;
  }
  .home-badge {
    width: 30%;
  }
  .home-name {
    font-size: 32px;
    margin-bottom: 56px;
  }
  .step-button-wrapper {
    padding: 24px 32px;
  }
  .button-expand-wrapper {
    padding: 24px 32px;
  }
  .logbook {
    padding: 24px 32px;
  }
  .tutorial-panel {
    padding: 40px 32px;
  }
  .tutorial-title-3 {
    height: 88px;
    margin-bottom: 56px;
  }
  .tutorial-title-2 {
    margin-bottom: 32px;
  }
  .step-button-arrow {
    width: 32px;
  }
  .step-image {
    max-width: 70%;
  }
  .scratch-image {
    max-width: 70%;
  }
  .scratch-cover {
    width: 70%;
    left: 15%;
  }
  .avatar {
    width: 56px;
    height: 56px;
  }
  .navbar {
    height: 56px;
  }
  .back-arrow {
    width: 26px;
  }
  .console-wrapper {
    width: 53%;
  }
  .step8-wrapper {
    width: 70%;
  }
  .step10-wrapper {
    width: 65%;
  }
  .logbook-notif {
    width: 32px;
    height: 32px;
    font-size: 20px;
    line-height: 32px;
    bottom: 68px;
  }
  /* .step-nav-wrapper {
    margin-top: 40px;
  } */
  .video-hint-overlay-bg {
    padding: 36px 24px;
  }
  .how-it-works {
    padding: 48px 29px 32px 56px;
    gap: 32px;
    max-height: 54vh;
  }
  .youtube-tutorial {
    width: 508px;
    height: 900px;
  }
  .logbook-container {
    padding: 36px 24px;
  }
  .logbook-wrapper {
    padding: 156px 0px 0px 0px;
  }
  .profile-container {
    padding: 48px 40px;
  }
  .profile-save {
    width: 200px;
  }
  .profile-avatar {
    width: 150px;
  }
  .profile-name {
    font-size: 24px;
    height: 72px;
  }
  .intro-video {
    width: 110%;
  }
  .exploded-image {
    width: 80%;
  }
}

@media only screen and (min-device-width: 1200px) {
  .main-container{
    max-width: 390px;
    max-height: 844px;
  }
}
